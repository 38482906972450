import React from 'react';
import PropTypes from 'prop-types';
import history from '../../history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export const toPath = ({ to = '', params = {} }) => (to.match(/:[a-z0-9]+/gi) || [])
  .map(replace => ({ replace, key: replace.substr(1) }))
  .reduce((link, { replace, key }) => link.replace(replace, params[key] || replace), to);

export const pushRoute = route => history.push(toPath(route));

class Link extends React.Component {
  static propTypes = {
    to:       PropTypes.string.isRequired,
    params:   PropTypes.shape(),
    children: PropTypes.node.isRequired,
    onClick:  PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
    params:  {},
  };

  handleClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    history.push(toPath(this.props));
  };

  render() {
    const {
      children,
      to,
      params,
      ...props
    } = this.props;
    return (
      <a href={toPath({ to, params })} {...props} onClick={this.handleClick}>
        {children}
      </a>
    );
  }
}

export default Link;
