import React from 'react';
import styled from 'react-emotion';
import { t } from 'ttag';
import Link from '../Link';

const Root = styled('div')({
    paddingBottom: 40,
});

const Authors = styled('div')({});

const Links = styled('div')({});

const Container = styled('div')({
    display:         'flex',
    justifyContent:  'space-between',
    height:          40,
    padding:         '10px',
    position:        'fixed',
    bottom:          0,
    left:            0,
    right:           0,
    backgroundColor: '#fff',
    span:            {
        padding: '0 10px',
    },
    a:               {
        padding: '0 10px',
    },
});
const Text = styled('span')({});
const Spacer = styled('span')({});

const Footer = () => (
    <Root>
        <Container>
            <Links>
                <Text>© LBS</Text>
                <Spacer>·</Spacer>
                <Link to="/">{t`Pagrindinis`}</Link>
                <Spacer>·</Spacer>
                <Link to="/about">{t`Apie`}</Link>
            </Links>
            <Authors />
        </Container>
    </Root>
);

export default Footer;
