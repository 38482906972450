import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import _get from 'lodash/get';
import { t } from 'ttag';
import Link, { toPath } from '../Link';
import paths from '../../constants/paths';

const query = gql`{
  me {
    id
    firstName
    lastName
    roles
  }
}`;

const menuLinkStyle = {
  color:          '#fff',
  textDecoration: 'none',
  margin:         '0 10px',
  '&:hover':      {
    textDecoration: 'underline',
  },
};

const MenuLink = styled(Link)(menuLinkStyle);
const LogoutLink = styled('a')(menuLinkStyle);

const Root = styled('div')({
  backgroundColor: '#5b94ff',
  height:          48,
  display:         'flex',
  alignItems:      'center',
  position:        'fixed',
  top:             0,
  left:            0,
  right:           0,
  zIndex:          100,
});

const Container = styled('div')({
  marginLeft:            'auto',
  marginRight:           'auto',
  padding:               10,
  height:                '100%',
  overflowX:             'scroll',
  whiteSpace:            'nowrap',
  lineHeight:            '28px',
  '-ms-overflow-style':  'none', // IE 10+
  'scrollbar-width':     'none', // Firefox
  '::-webkit-scrollbar': {
    width:  0,
    height: 0,
  },
});

const hasRole = (me, role) => (_get(me, 'roles', []) || []).indexOf(role) !== -1;

const toFullName = (user) => {
  if (!user) {
    return null;
  }

  return `${user.firstName} ${user.lastName}`;
};

const Ph = styled('div')({
  display: 'block',
  height:  48,
  width:   '100%',
});

const Header = ({ me }) => {
  const name = toFullName(me);
  return (
    <React.Fragment>
      <Root>
        <Container>
          <MenuLink to={paths.home}>{t`Pagrindinis`}</MenuLink>
          {hasRole(me, 'admin') && <MenuLink to={paths.users}>{t`Valdyti vartotojus`}</MenuLink>}
          {hasRole(me, 'root') && <MenuLink to={paths.manage}>{t`Egzaminų valdymas`}</MenuLink>}

          {me && (<LogoutLink href={toPath({ to: paths.logout })}>{t`Atsijungti (${name})`}</LogoutLink>)}
        </Container>
      </Root>
      <Ph />
    </React.Fragment>
  );
};

Header.propTypes = {
  me: PropTypes.shape({
    id:        PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName:  PropTypes.string.isRequired,
  }),
};

Header.defaultProps = {
  me: null,
};

export default graphql(query, {
  options: {
    errorPolicy: 'all',
  },
  props:   ({ data: { me = null } }) => ({ me }),
})(Header);
