import React from 'react';
import PropTypes from 'prop-types';
import styled, { injectGlobal } from 'react-emotion';
import normalize from 'emotion-normalize';
import Header from '../Header';
import Footer from '../Footer';
import Loading from './Loading';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  ${normalize}
  
  html, body {
    font-family: Roboto;
    font-size: 16px;  
  }
  
  *  {
    box-sizing: border-box;  
  }
  
  pre {
    white-space: pre;
    max-width: 100%;
    overflow-x: scroll;
    margin: 0;
  }
`;

const Container = styled('div')({});
const Content = styled('div')({
  margin: 10,
});

const Layout = ({ children, footerEnabled }) => (
  <Container>
    <Header />
    <Content>{children}</Content>
    {footerEnabled && <Footer />}
    <Loading />
  </Container>
);

Layout.propTypes = {
  children:      PropTypes.node.isRequired,
  footerEnabled: PropTypes.bool,
};

Layout.defaultProps = {
  footerEnabled: true,
};

export default Layout;
